import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useRedirect } from '../../hooks/useRedirect'
import {
  CheckoutContext,
  sendDemand,
  DEMAND_STATUS,
} from '../../components/context/CheckoutContext'
import { colors } from '../../theme'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import {
  Button,
  StyledButton,
  Spinner,
  StyledSpinner,
} from '@monbanquet/crumble'
import { navigate } from '../../components/Link'
import { buildAskForQuoteBody, computeNextPageWithBaseUri } from '../../util'
import { EventContext } from '../../components/context/EventContext'
import { PhoneContext } from '../../components/context/PhoneContext'
import DemandeLayout from '../../components/DemandeLayout'
import { CustomerContext } from '../../components/context/CustomerContext'

const DemandSourcePage = ({ location: { state } }) => {
  const redirect = useRedirect(!state || !state.next, '/demande/format')
  const [submittingState, setSubmittingState] = useState({
    isSubmitting: false,
    demandSource: '',
  })
  const [error, setError] = useState()

  const { state: checkoutState, dispatch: dispatchCheckoutCtx } = useContext(
    CheckoutContext,
  )

  const {
    state: { e164 },
  } = useContext(PhoneContext)

  const {
    state: eventState,
    categories,
    dispatch: dispatchEventCtx,
  } = useContext(EventContext)

  const { customer } = useContext(CustomerContext)

  const demandSources = [
    'Je suis déjà client',
    'Recherche Google ou autre moteur de recherche',
    'Publicité en ligne',
    "Recommandation d'un ami",
    "Recommandation d'un collègue",
    'Réseaux Sociaux',
    "J'ai été contacté par quelqu'un de Monbanquet.fr",
    "J'ai participé à un événement avec des produits Monbanquet.fr",
    'Je vous ai découvert sur un salon',
    'Presse (journal, TV, Radio...)',
  ]

  const submitDemandForm = async demandSource => {
    const nextUrl = computeNextPageWithBaseUri('demande', 'source', {
      isUserLoggedIn: !!customer,
      customer,
    })

    if (nextUrl) {
      navigate(nextUrl, { state: { next: true } })
    } else {
      const body = buildAskForQuoteBody({
        ...checkoutState,
        ...eventState,
        demandSource,
        categories,
      })

      setSubmittingState({ isSubmitting: true, demandSource })
      const { status, response } = await sendDemand(dispatchCheckoutCtx, body)

      if (status === DEMAND_STATUS.OK) {
        navigate(
          `${process.env.GATSBY_VENUS_FRONT_URL}/evenements/${response.demandId}`,
          { newTab: false },
        )
      } else if (status === DEMAND_STATUS.NEED_MANUAL_OPERATION) {
        navigate('/demande/merci', { state: { quote: true } })
      } else if (status === DEMAND_STATUS.KO) {
        const errMsg = `Nous n'avons pas réussi à enregistrer votre demande. Veuillez nous contacter sur le chat ou par téléphone au ${e164}.`
        setError(errMsg)
        dispatchEventCtx({
          type: 'UPDATE',
          payload: { demandSource: null },
        })
      }
    }
  }

  const selectSource = d => {
    dispatchEventCtx({
      type: 'UPDATE_DEMAND_SOURCE',
      payload: { demandSource: d },
      analytics: { label: 'From_DemandSource' },
    })
    submitDemandForm(d)
  }

  const noDemandSource = () => {
    dispatchEventCtx({
      type: 'UPDATE_DEMAND_SOURCE',
      payload: { demandSource: null },
      analytics: { label: 'From_DemandSource' },
    })
    submitDemandForm(null)
  }

  return (
    <StyledDemandSourcePage>
      <DemandeLayout>
        {redirect ? null : (
          <div>
            <SalesPictureTitle
              title={`Comment avez-vous entendu parler de Monbanquet\u00A0?`}
            />

            <p className="error">{error}</p>

            <div className="sources">
              {demandSources.map(d => (
                <Button
                  key={d}
                  className={`source ${
                    submittingState.isSubmitting &&
                    submittingState.demandSource === d
                      ? 'submitting'
                      : ''
                  }`}
                  disabled={submittingState.isSubmitting}
                  onClick={() => selectSource(d)}
                >
                  {submittingState.isSubmitting &&
                  submittingState.demandSource === d ? (
                    <Spinner />
                  ) : (
                    <div className="name">{d}</div>
                  )}
                </Button>
              ))}
              <Button className="source autre" onClick={noDemandSource}>
                <div className="name">{`Autre`}</div>
              </Button>
            </div>
            <div className="btn-bar">
              <Button id="previous" onClick={() => navigate(-1)}>
                <div>&rarr;</div>
                <span>&nbsp;Précédent</span>
              </Button>
            </div>
          </div>
        )}
      </DemandeLayout>
    </StyledDemandSourcePage>
  )
}

const StyledDemandSourcePage = styled.div`
  .page-content {
    max-width: 500px;
  }

  display: flex;
  flex-direction: column;

  color: ${colors.brand.black};

  h1 {
    margin-bottom: 30px;
  }

  .sources {
    display: flex;
    flex-direction: column;

    ${StyledButton} {
      color: inherit;
      text-transform: none;
      letter-spacing: unset;
      text-align: left;
      height: auto;
    }

    ${StyledButton}.source {
      position: relative;
      padding: 16px 20px;
      background-color: #fff;

      &[disabled] {
        cursor: default;
        background-color: ${colors.background.semiLight};
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:hover {
        .icon {
          opacity: 1;
        }
      }

      & > :not(:last-child) {
        margin-right: 10px;
      }

      .name {
        font-weight: 500;
        font-size: 1rem;
      }

      &.autre {
        background-color: ${colors.background.semiLight};
        .name {
          font-weight: normal;
          text-align: center;
          flex-grow: 1;
        }
      }

      &.submitting {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;

        ${StyledSpinner} {
          height: 18px;
        }
      }
    }
  }

  .error {
    margin-bottom: 10px;
    color: ${colors.brand.dark2};
  }
`

export default DemandSourcePage
export { StyledDemandSourcePage }
